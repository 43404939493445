const radii = [{
    key: 'R-6',
    name: "6 mm (default)",
    value: 6,
    price: 0
},{
    key: 'R-4',
    name: "4 mm",
    value: 4,
    price: 2
},{
    key: 'R-1.5',
    name: "1.5 mm",
    value: 1.5,
    price: 10
}]

export default radii