import React from 'react'
import DxfParser from 'dxf-parser'
import { BP2D } from 'binpackingjs'
import { useDropzone } from 'react-dropzone';
import materials from './materials'
import innerradii from './innerradii'
import calculateBPostPrice from './bbpost'
import getDXFBoundingBox from './dxf'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Typography, Container, IconButton, FormHelperText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info'
import HelpIcon from '@mui/icons-material/Help'
import { Stack } from '@mui/system';
import './App.css';

const parser = new DxfParser()
const { Bin, Box, Packer, heuristics } = BP2D

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

function calculateSheets(parts, material) {
  const boxes = []
  let bins = []
  let tempBoxes = []

  parts.forEach(i => {
    for (let count = 0; count < i.count; count++) {
      if (material.panelLength > Math.max(i.bbox.width, i.bbox.height) && material.panelWidth > Math.min(i.bbox.width, i.bbox.height))
      boxes.push(new Box(i.bbox.width, i.bbox.height, new heuristics.BestAreaFit()))
    }
  })

  if (parts.length === 0) {
    return 0
  }

  let panelCount = 0
  do {
    bins = []
    panelCount += material.minPanels
    const fullPanels = Math.floor(panelCount)
    const partialPanel = panelCount - fullPanels

    for (let fullPanelCount = 0; fullPanelCount < fullPanels; fullPanelCount++) {
      bins.push(new Bin(material.panelWidth, material.panelLength))
    }
    if (partialPanel !== 0) {
      bins.push(new Bin(material.panelWidth, (material.panelLength * partialPanel)))
    }

    const packer = new Packer(bins.map(b => new Bin(b.width, b.height, new heuristics.BestAreaFit())))

    tempBoxes = boxes.map(b => new Box(b.width, b.height))
    packer.pack(tempBoxes)

  } while (tempBoxes.filter(b => b.packed === false).length > 0)

  return panelCount
}

function calculatePrice(drawingFiles) {
  const perMaterial = groupBy(drawingFiles, 'selectedMaterialKey')
  const perRadii = groupBy(drawingFiles, 'selectedRadiusKey')
  let price = []

  if (drawingFiles.length > 0) {
    price.push({ price: drawingFiles.length * 5, description: 'Validatie ' + drawingFiles.length + (drawingFiles.length === 1 ? ' bestand ' : ' bestanden ') })
  }

  const totalObjects = drawingFiles.reduce((partialSum, a) => partialSum + a.count, 0)
  if (totalObjects > 0) {
    price.push({ price: totalObjects * 7, description: 'Uitwerken toolpaths voor ' + totalObjects + (totalObjects === 1 ? ' item ' : ' items ') })
    price.push({ price: totalObjects * 5, description: 'Afwerken en voorbereiden verzending ' + totalObjects + (totalObjects === 1 ? ' item ' : ' items ') })
  }

  Object.keys(perMaterial).forEach(k => {
    const partsOfSameMaterial = perMaterial[k]
    const material = partsOfSameMaterial[0].selectedMaterial
    const sheetCount = calculateSheets(partsOfSameMaterial, material)
    price.push({ price: sheetCount * material.pricePerPanel, description: 'Plaatmateriaal ' + sheetCount + (sheetCount === 1 ? ' paneel ' : ' panelen ') + material.name })
  })

  Object.keys(perRadii).forEach(r => {
    const partsWithSameRadius = perRadii[r]
    const radius = partsWithSameRadius[0].selectedRadius
    const radiusCnt = partsWithSameRadius.reduce((partialSum, a) => partialSum + a.count, 0)
    if (radius.price > 0) {
      price.push({ price: radiusCnt * radius.price, description: 'Afwijkende minimale interne radius van ' + radius.name + ' voor ' + radiusCnt + (radiusCnt === 1 ? ' item ' : ' items ') })
    }
  })

  // calculate shipping cost
  calculateBPostPrice(drawingFiles, price)

  return price
}

function getBoundingBox(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      const dxf = parser.parseSync(reader.result);
      const {min, max} = getDXFBoundingBox(dxf)
      resolve({
        width: Math.abs(max.x - min.x),
        height: Math.abs(max.y - min.y),
        depth: Math.abs(max.z - min.z)
      })
    }
    reader.readAsText(file)
  })
}

function DetailDialog (activeRow, infoOpen, handleInfoClose) {
  if (activeRow == null) {
    return null
  }

  return (
    <Dialog open={infoOpen} onClose={handleInfoClose} fullWidth={true}>
    <DialogTitle>Info</DialogTitle>
    <DialogContent>
    <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>Breedte</TableCell>
                  <TableCell align="right">{Math.round(activeRow.bbox.width * 100) / 100} mm</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>Hoogte</TableCell>
                  <TableCell align="right">{Math.round(activeRow.bbox.height * 100) / 100} mm</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>Dikte</TableCell>
                  <TableCell align="right">{Math.round(activeRow.bbox.depth * 100) / 100} mm</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>Materiaal</TableCell>
                  <TableCell align="right">{activeRow.selectedMaterial.name}</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>Aantal</TableCell>
                  <TableCell align="right">{activeRow.count}</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>Gewicht</TableCell>
                  <TableCell align="right">{Math.round(activeRow.weigth * 100) / 100} kg</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>Minimale interne radius</TableCell>
                  <TableCell align="right">{activeRow.selectedRadius.name}</TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleInfoClose}>Sluiten</Button>
    </DialogActions>
  </Dialog>)
}

function App() {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/x-dxf': ['.dxf'],
      'image/svg+xml': ['.svg']
    },
    multiple: false
  })

  const [uploadOpen, setUploadOpen] = React.useState(false)
  const [infoOpen, setInfoOpen] = React.useState(false)
  const [drawingFiles, setDrawingFiles] = React.useState([])
  const [selectedMaterial, setSelectedMaterial] = React.useState(materials[0])
  const [selectedRadius, setSelectedRadius] = React.useState(innerradii[0])
  const [count, setCount] = React.useState(1)
  const [price, setPrice] = React.useState([])
  const [activeRow, setActiveRow] = React.useState(null)

  const handleClickOpen = () => {
    setUploadOpen(true)
  }

  const handleUpload = async () => {
    const newFiles = []
    await Promise.all(acceptedFiles.map(async f => {
      const bbox = await getBoundingBox(f)
      if (bbox.depth === 0) {
        bbox.depth = selectedMaterial.thickness
      }
      newFiles.push({
        file: f,
        bbox,
        weigth: selectedMaterial.density * (selectedMaterial.thickness / 1000) * (bbox.width / 1000) * (bbox.height / 1000),
        selectedMaterial,
        selectedMaterialKey: selectedMaterial.key,
        selectedRadius,
        selectedRadiusKey: selectedRadius.key,
        count: parseInt(count)
      })
    }))

    acceptedFiles.length = 0
    const newList = [...drawingFiles, ...newFiles]
    setDrawingFiles(newList)

    // update price
    let priceLines = calculatePrice(newList)
    setPrice(priceLines)

    setSelectedMaterial(materials[0])
    setSelectedRadius(innerradii[0])
    setCount(1)
    setUploadOpen(false)
  }

  const handleCancel = () => {
    // reset input form
    acceptedFiles.length = 0
    setSelectedMaterial(materials[0])
    setSelectedRadius(innerradii[0])
    setCount(1)
    setUploadOpen(false)
  }

  const handleInfoClose = () => {
    setInfoOpen(false)
  }

  const recalculate = () => {
    let priceLines = calculatePrice(drawingFiles)
    setPrice(priceLines)
  }

  const clear = () => {
    setDrawingFiles([])
    setPrice([])
  }

  const removeFile = (item) => {
    const newList = drawingFiles.filter(f => f !== item)
    let priceLines = calculatePrice(newList)

    setDrawingFiles(newList)
    setPrice(priceLines)
  }

  const showInfo = (item) => {
    setInfoOpen(true)
    setActiveRow(item)
  }

  return (
    <div className="App">
      <Container maxWidth='lg'>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}><Typography component="h2" variant="h4" color="primary" gutterBottom>Bestanden</Typography></Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>File</TableCell>
                    <TableCell align="right">Breedte</TableCell>
                    <TableCell align="right">Hoogte</TableCell>
                    <TableCell align="right">Materiaal</TableCell>
                    <TableCell align="right">Aantal</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {drawingFiles.map((row, i) => (
                    <TableRow key={'df_' + i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{row.file.path}</TableCell>
                      <TableCell align="right">{Math.round(row.bbox.width * 100) / 100} mm</TableCell>
                      <TableCell align="right">{Math.round(row.bbox.height * 100) / 100} mm</TableCell>
                      <TableCell align="right">{row.selectedMaterial.name}</TableCell>
                      <TableCell align="right">{row.count}</TableCell>
                      <TableCell align="right">
                        <IconButton edge="start" color="inherit" aria-label="verwijderen" onClick={() => removeFile(row)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton edge="start" color="inherit" aria-label="info" onClick={() => showInfo(row)}>
                          <InfoIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" onClick={handleClickOpen}>Upload bestand (DXF)</Button>
            <Button variant="outlined" onClick={clear} sx={{ ml: 4 }}>Alles wissen</Button>
          </Grid>
          <Grid item xs={12}><Typography component="h2" variant="h4" color="primary" gutterBottom sx={{ mt: 5 }}>Prijsberekening</Typography></Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Omschrijving</TableCell>
                    <TableCell align="right">Bedrag</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {price.map((row, i) => (
                    <TableRow key={'pl_' + i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{row.description}</TableCell>
                      <TableCell align="right">{row.price}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow key={'pl_total'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className='total-line'>
                    <TableCell>Totale kostprijs (excl. btw)</TableCell>
                    <TableCell align="right">{price.reduce((partialSum, a) => partialSum + a.price, 0)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={uploadOpen} onClose={handleUpload}>
        <DialogTitle>Upload</DialogTitle>
        <DialogContent>
          {acceptedFiles.length === 0 &&
            (<div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop jouw DXF bestand hier, of klik om een bestand te slecteren.</p>
            </div>)
          }
          {acceptedFiles.length === 1 &&
            (<div>{acceptedFiles[0].path}</div>)
          }
          <Stack spacing={3} sx={{ minWidth: 550, mt: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="material-select-label">Material</InputLabel>
              <Select labelId="material-select-label" id="material-select" value={selectedMaterial} label="Material" onChange={(evt) => { setSelectedMaterial(evt.target.value) }}>
                {materials.map(m => <MenuItem value={m} key={m.name}>{m.name}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="radius-select-label">Radius</InputLabel>
              <Select labelId="radius-select-label" id="radius-select" value={selectedRadius} label="Radius" onChange={(evt) => { setSelectedRadius(evt.target.value) }}>
                {innerradii.map(m => <MenuItem value={m} key={m.name}>{m.name}</MenuItem>)}
              </Select>
              <FormHelperText>Dit is de minimale radius voor binnenhoeken. Hoe kleiner hoe complexer.</FormHelperText>
            </FormControl>
            <TextField label="Count" type="number" InputProps={{ inputProps: { min: 1, max: 1000 } }} variant="filled" autoComplete="new-password" onChange={(evt) => { setCount(evt.target.value) }} value={count} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Annuleren</Button>
          <Button onClick={handleUpload}>Upload</Button>
        </DialogActions>
      </Dialog>
      { DetailDialog(activeRow, infoOpen, handleInfoClose) }
    </div>
  )
}

export default App;
