const materials = [{
    key: 'MDF-12',
    name: "MDF 12mm",
    density: 800,
    thickness: 12,
    minPanels: 1,
    pricePerPanel: 30,
    panelWidth: 1220,
    panelLength: 2440
},{
    key: 'MDF-18',
    name: "MDF 18mm",
    density: 800,
    thickness: 18,
    minPanels: 0.5,
    pricePerPanel: 40,
    panelWidth: 1220,
    panelLength: 2440
},{
    key: 'MDF-18-BL',
    name: "MDF 18mm Zwart",
    density: 800,
    thickness: 18,
    minPanels: 0.5,
    pricePerPanel: 90,
    panelWidth: 1220,
    panelLength: 3050
},{
    key: 'MDF-30',
    name: "MDF 30mm",
    density: 800,
    thickness: 30,
    minPanels: 1,
    pricePerPanel: 85,
    panelWidth: 1220,
    panelLength: 3050
},{
    key: 'MPX-12-Oakoume',
    name: "Multiplex Okoume 12mm",
    density: 500,
    thickness: 12,
    minPanels: 1,
    pricePerPanel: 95,
    panelWidth: 1530,
    panelLength: 3050
}, {
    key: 'MPX-18-Oakoume',
    name: "Multiplex Okoume 18mm",
    density: 500,
    thickness: 18,
    minPanels: 1,
    pricePerPanel: 125,
    panelWidth: 1220,
    panelLength: 2500
}, {
    key: 'MPX-22-Oakoume',
    name: "Multiplex Okoume 22mm",
    density: 500,
    thickness: 22,
    minPanels: 1,
    pricePerPanel: 155,
    panelWidth: 1220,
    panelLength: 2500
}, {
    key: 'BPX-09-GLAD',
    name: "Betonplex glad 9mm",
    density: 500,
    thickness: 9,
    minPanels: 1,
    pricePerPanel: 75,
    panelWidth: 1250,
    panelLength: 2500
}]

export default materials