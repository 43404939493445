import deepClone from "deep-clone"

const BBPOS_MAX_WEIGHT_KG = 27

function calculateBPostLength(a) {
    if (a.bbox.width > a.bbox.height) {
        return a.bbox.width + (2 * a.bbox.height) + (2 * a.selectedMaterial.thickness)
    } else {
        return a.bbox.height + (2 * a.bbox.width) + (2 * a.selectedMaterial.thickness)
    }
}

function calculatePackageCount(parts, split) {
    const result = { selfShip: false, packages: 0, totalWeigth: 0, failed: false}

    if (parts.length === 0) {
        result.failed = true
        return result
    }

    const maxLongestSide = parts.reduce((maxDim, a) => Math.max(maxDim, a.bbox.width, a.bbox.height), 0)
    const maxShortestSide = parts.reduce((maxDim, a) => Math.max(maxDim, Math.min(a.bbox.width, a.bbox.height)), 0)
    const maxTotalSides = parts.reduce((maxDim, a) => Math.max(maxDim, calculateBPostLength(a)), 0)
    const totalWeigth = parts.reduce((weight, a) => weight + (a.weigth * a.count), 0)
    const totalHeight = parts.reduce((height, a) => height + a.selectedMaterial.thickness, 0)

    result.totalWeigth = totalWeigth

    if (maxLongestSide > 1500 || maxTotalSides > 3000) {
        result.selfShip = true
    } else {
        // can be transported via bpost
        const maxSinglePackageHeight = 3000 - maxLongestSide - (2 * maxShortestSide)
        if (maxSinglePackageHeight > totalHeight) {
            // we only have to split by weigth
            result.packages = Math.ceil(totalWeigth / BBPOS_MAX_WEIGHT_KG)
        } else {
            // we have to split the package in multiple partials
            if (split) {
                let solved = false
                let packageCount = 0
                let splitPoint = 1
                let copy = [...parts]
                let packages = []

                do {
                    if (splitPoint > copy.length) {
                        // all fits a single package
                        packageCount++
                        packages.push({ parts: copy })
                        solved = true
                    } else {
                        let subCopy = [...copy]
                        let testSet = subCopy.splice(0, splitPoint)
                        let testResult = calculatePackageCount(testSet, false)
                        if (testResult.failed === true) {
                            if (splitPoint > 1) {
                                // remove items that fit into package
                                const pack = copy.splice(0, splitPoint - 1)
                                packages.push({ parts: pack })
                                packageCount++
                                // split remaining parts
                                splitPoint = 1
                            } else {
                                // you can't split a single part... so something wrong
                                result.failed = true
                                return result
                            }
                        } else {
                            splitPoint++
                        }
                    }
                } while (!solved)

                result.packageCount = packageCount
            } else {
                result.failed = true
            }
        }
    }

    return result
}

export default function calculateBPostPrice(drawingFiles, price) {
    // we transform files into objects (easier to split packages)
    const parts = []
    drawingFiles.forEach(file => {
        for (let i = 0; i < file.count; i++) {
            let copy = deepClone(file)
            copy.count = 1
            parts.push(copy)
        }
    })

    const result = calculatePackageCount(parts, true)
    if (result.selfShip || result.packages > 5) {
        price.push({ price: 100, description: 'Verzendkost grote objecten.' })
    } else {
        price.push({ price: result.packages * 20, description: 'Verzendkost ' + (Math.round(result.totalWeigth * 100) / 100) + 'kg' })
    }
}
