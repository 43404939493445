export default function getDXFBoundingBox(dxf) {
    const measurement = dxf.header['$MEASUREMENT'] // 0 = English; 1 = Metric
    const unit = dxf.header['$INSUNITS'] || 4 // if not provided, we asume millimeters
    
    
    if (dxf.header['$EXTMIN'] && dxf.header['$EXTMAX']) {
        const min = dxf.header['$EXTMIN']
        const max = dxf.header['$EXTMAX']

        const width = Math.abs(max.x - min.x)
        const height = Math.abs(max.y - min.y)
        const depth = Math.abs(max.z - min.z)
        if (width < 100000 || height < 100000 || depth < 100000) {
            // sometimes these numbers are creazy high and can't be trusted
            return convertRangeToMM({min, max}, unit)
        }
    }
        
    let { found, min, max } = findMinMax(dxf, dxf.entities)

    if (!found) {
        console.error('Could not parse DXF file')
        return {min: {x: 0, y: 0, z: 0}, max: {x: 0, y: 0, z: 0}}    
    }

    return convertRangeToMM({min, max}, unit)
}

function findMinMax(dxf, entities) {
    let min = { x: Number.MAX_SAFE_INTEGER, y: Number.MAX_SAFE_INTEGER, z: Number.MAX_SAFE_INTEGER }
    let max = { x: Number.MIN_SAFE_INTEGER, y: Number.MIN_SAFE_INTEGER, z: Number.MIN_SAFE_INTEGER }
    let found = false

    entities.forEach(entity => {
        if (entity.type === 'INSERT' && dxf.blocks) {
            // is a reference to a block, find block
            const block = dxf.blocks[entity.name]
            const blockMinMax = findMinMax(dxf, block.entities)
            if (blockMinMax.found) {
                found = true
                min = {
                    x: Math.min(min.x, blockMinMax.min.x),
                    y: Math.min(min.y, blockMinMax.min.y),
                    z: Math.min(min.z, blockMinMax.min.z)
                }
                max = {
                    x: Math.max(max.x, blockMinMax.max.x),
                    y: Math.max(max.y, blockMinMax.max.y),
                    z: Math.max(max.z, blockMinMax.max.z)
                }
            }
        } else if (entity.vertices) {
            entity.vertices.forEach(vertice => {
                found = true
                min = {
                    x: Math.min(min.x, vertice.x),
                    y: Math.min(min.y, vertice.y),
                    z: Math.min(min.z, vertice.z)
                }
                max = {
                    x: Math.max(max.x, vertice.x),
                    y: Math.max(max.y, vertice.y),
                    z: Math.max(max.z, vertice.z)
                }
            })
        }
    })
    return { found, min, max }
}

function convertRangeToMM(minmax, unit) {
    return { min: convertToMM(minmax.min, unit), max: convertToMM(minmax.max, unit)  }
}

function convertToMM(point, unit) {
    // https://help.autodesk.com/view/ACD/2021/ENU/?guid=GUID-A58A87BB-482B-4042-A00A-EEF55A2B4FD8

    switch (unit) {
        case 1: // inch
            return { x: point.x * 25.4, y: point.y * 25.4, z: point.z * 25.4 }
        case 5: // cm
            return { x: point.x * 10, y: point.y * 10, z: point.z * 10 }
        case 6: // m
            return { x: point.x * 1000, y: point.y * 1000, z: point.z * 1000 }
        default:
            return point;
    }
}
